<template>
  <div class="flex relative" data-cy="dashboard-nav">
    <div
      class="flex flex-col h-screen justify-between z-50 relative bg-login-bg group/dashboard-nav"
      :class="[
        props.testMode ? 'pt-15' : 'pt-3',
        { 'w-[200px]': sidebarExpanded }
      ]"
    >
      <expand-button
        :expanded="sidebarExpanded"
        class="absolute z-50 right-0 -mr-2-5 mt-2-5 cursor-pointer invisible group-hover/dashboard-nav:visible"
        data-cy="dashboard-nav-expand-button"
        @click="toggleSidebar"
      />
      <div class="flex flex-col justify-center my-1 space-y-2">
        <router-link
          class="mb-5 border-b border-gray-700 flex justify-start cursor-pointer focus:outline-none"
          :class="[sidebarExpanded ? 'px-4 pb-6' : 'pl-3 pb-4']"
          to="/"
        >
          <icon-base
            v-if="sidebarExpanded"
            class="place-self-center my-2"
            height="18"
            width="120"
            icon="logo-expanded"
            :icon-name="$t('COMMON.LOGO')"
          />
          <icon-base
            v-else
            class="place-self-center"
            height="28"
            width="28"
            icon="logo"
            :icon-name="$t('COMMON.LOGO')"
          />
        </router-link>

        <access-control :funders="true">
          <sidebar-button
            v-if="isNotLendflowOrClientUser"
            to="/funding-partners/details"
            :sidebar-expanded="sidebarExpanded"
            class="relative mx-2"
            :tooltip-label="$t('SIDEBAR.DETAILS')"
          >
            <template v-slot:icon>
              <div class="w-10 h-10 flex justify-center">
                <icon-base
                  class="absolute icon-business"
                  height="40"
                  width="40"
                  icon="businesses"
                  icon-color="white"
                />
              </div>
            </template>
            <template v-slot:label>
              {{ $t("SIDEBAR.DETAILS") }}
            </template>
          </sidebar-button>
        </access-control>

        <access-control :lendflow="true" :clients="true">
          <sidebar-button
            to="/dashboard"
            :sidebar-expanded="sidebarExpanded"
            class="relative mx-2"
            :tooltip-label="$t('SIDEBAR.DASHBOARD')"
          >
            <template v-slot:icon>
              <div class="w-10 h-10 flex justify-center">
                <icon-base
                  class="absolute icon-dashboard"
                  height="40"
                  width="40"
                  :icon="IconDashboard"
                  icon-color="white"
                />
              </div>
            </template>
            <template v-slot:label>
              {{ $t("SIDEBAR.DASHBOARD") }}
            </template>
          </sidebar-button>
          <sidebar-button
            to="/deals"
            class="deals__menu-btn mx-2"
            :sidebar-expanded="sidebarExpanded"
            :tooltip-label="$t('SIDEBAR.DEALS.TITLE')"
            :active-condition="dealsActiveCondition"
          >
            <recent-submenu
              v-show="dealsHistory.length"
              class="z-40 absolute left-full deals__recent-submenu"
              :recent-list="recentSubmenuList"
              placeholder="Search Deals"
            />
            <template v-slot:icon>
              <div class="w-10 h-10 flex justify-center">
                <icon-base
                  class="absolute icon-deals"
                  height="40"
                  width="40"
                  icon="deals"
                  icon-color="white"
                />
              </div>
            </template>
            <template v-slot:label>
              {{ $t("SIDEBAR.DEALS.TITLE") }}
            </template>
          </sidebar-button>
        </access-control>
        <access-control lendflow>
          <sidebar-button
            to="/activity-hub"
            class="deals__menu-btn mx-2"
            :sidebar-expanded="sidebarExpanded"
            :tooltip-label="$t('ACTIVITY.ACTIVITY_HUB')"
          >
            <template v-slot:icon>
              <div class="w-10 h-10 flex justify-center items-center">
                <icon-base
                  class="absolute icon-activity-hub"
                  height="25"
                  width="25"
                  :icon="IconActivityHub"
                />
              </div>
            </template>
            <template v-slot:label>{{ $t("ACTIVITY.ACTIVITY_HUB") }}</template>
          </sidebar-button>
        </access-control>
        <access-control lendflow>
          <sidebar-button
            to="/funders"
            :sidebar-expanded="sidebarExpanded"
            class="relative mx-2"
            :tooltip-label="$t('SIDEBAR.FUNDERS')"
            :active-condition="fundersActiveCondition"
          >
            <template v-slot:icon>
              <div class="flex justify-center w-10 h-10">
                <icon-base
                  class="absolute icon-funders"
                  height="40"
                  width="40"
                  icon="funders"
                  icon-color="white"
                />
              </div>
            </template>
            <template v-slot:label>
              {{ $t("SIDEBAR.FUNDERS") }}
            </template>
          </sidebar-button>
        </access-control>
        <sidebar-button
          v-if="canViewBusinessEntityProfiles && !auth.isAccelerateTaxViewer"
          to="/businesses"
          :sidebar-expanded="sidebarExpanded"
          class="relative mx-2"
          :tooltip-label="$t('BUSINESS_PROFILES.MAIN_ROUTE_NAME')"
          :active-condition="businessesActiveCondition"
          data-cy="businesses-profiles-nav"
        >
          <template v-slot:icon>
            <div class="w-10 h-10 flex justify-center items-center">
              <icon-base
                class="absolute icon-business"
                height="40"
                width="40"
                icon="businesses"
              />
            </div>
          </template>
          <template v-slot:label>
            {{ $t("BUSINESS_PROFILES.MAIN_ROUTE_NAME") }}
          </template>
        </sidebar-button>
        <sidebar-button
          v-if="canViewIndividualEntityProfiles && !auth.isAccelerateTaxViewer"
          to="/individuals"
          :sidebar-expanded="sidebarExpanded"
          class="relative mx-2"
          :active-condition="individualProfilesActiveCondition"
          :tooltip-label="$t('INDIVIDUAL_PROFILES.MAIN_ROUTE_NAME')"
        >
          <template #icon>
            <div class="w-10 h-10 flex justify-center items-center">
              <icon-base
                class="absolute icon-persons"
                height="40"
                width="40"
                icon="persons"
              />
            </div>
          </template>
          <template #label>
            {{ $t("INDIVIDUAL_PROFILES.MAIN_ROUTE_NAME") }}
          </template>
        </sidebar-button>
        <access-control :clients="true">
          <sidebar-button
            to="/integrations"
            :sidebar-expanded="sidebarExpanded"
            class="relative mx-2"
            tooltip-label="Integrations"
          >
            <template v-slot:icon>
              <div class="w-10 h-10 flex justify-center">
                <icon-base
                  class="absolute icon-integrations"
                  height="40"
                  width="40"
                  icon="integrations"
                  icon-color="white"
                />
              </div>
            </template>
            <template v-slot:label>{{ $t("SIDEBAR.INTEGRATIONS") }}</template>
          </sidebar-button>
        </access-control>
        <access-control :roles="[CLIENT_ADMIN]">
          <sidebar-button
            to="/customize"
            :sidebar-expanded="sidebarExpanded"
            class="relative mx-2"
            tooltip-label="Customize"
          >
            <template v-slot:icon>
              <div class="w-10 h-10 flex justify-center">
                <icon-base
                  class="absolute icon-customize"
                  height="40"
                  width="40"
                  icon="customize"
                  icon-color="white"
                />
              </div>
            </template>
            <template v-slot:label>{{ $t("SIDEBAR.CUSTOMIZE") }}</template>
          </sidebar-button>
        </access-control>
        <access-control :roles="reportsAccessRoles">
          <sidebar-button
            to="/reports/"
            :sidebar-expanded="sidebarExpanded"
            class="relative mx-2"
            tooltip-label="Reports"
          >
            <template v-slot:icon>
              <div class="w-10 h-10 flex justify-center">
                <icon-base
                  class="absolute icon-reports"
                  height="40"
                  width="40"
                  icon="reports"
                  icon-color="white"
                />
              </div>
            </template>
            <template v-slot:label>{{ $t("SIDEBAR.REPORTS") }}</template>
          </sidebar-button>
        </access-control>
      </div>
      <div class="flex flex-col justify-end">
        <div class="flex flex-col p-2">
          <sidebar-test-mode-button-with-modal :test-mode="props.testMode" />
        </div>
        <sidebar-button
          to="/profile/account"
          :sidebar-expanded="sidebarExpanded"
          :account-button="true"
          :tooltip-label="$t('SIDEBAR.PROFILE.TITLE')"
        >
          <template v-slot:icon>
            <icon-base
              class="place-self-center shrink-0 basis-6 icon-profile"
              height="24"
              width="24"
              view-box="0 0 48 48"
              icon="profile"
            />
          </template>
          <template v-slot:label>
            <div v-if="user" class="flex shrink flex-col justify-between">
              <span v-tooltip="fullUserName" class="truncate">
                {{ fullUserName }}
              </span>
              <span
                v-tooltip="
                  !!user.roles.length && getUserRoles(user.roles)?.join(', ')
                "
                class="text-xs font-normal truncate"
              >
                {{ getUserRoles(user.roles)?.join(", ") }}
              </span>
            </div>
          </template>
        </sidebar-button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import type { IDealHistoryRecord } from "@/models/state";
import type { IUser } from "@/models/users";
import { usePermissions } from "@/hooks/auth";
import { Ability, PermissionSubject } from "@/enums/auth";
import { useAuth } from "@/hooks/auth";
</script>
<script setup lang="ts">
import {
  ADMIN,
  SUPER_ADMIN,
  CLIENT_ADMIN,
  CLIENT_ROLE_GROUP,
  LENDFLOW_ROLE_GROUP,
  USER
} from "@/helpers/constants";
import { getUserRoles } from "@/helpers/formatting";
import ExpandButton from "@/components/ui/buttons/ExpandButton.vue";
import SidebarButton from "@/components/sidebar/SidebarButton.vue";
import RecentSubmenu from "@/components/sidebar/RecentSubmenu.vue";
import SidebarTestModeButtonWithModal from "@/components/sidebar/SidebarTestModeButtonWithModal.vue";
import IconActivityHub from "@/components/icons/IconActivityHub.vue";
import IconDashboard from "@/components/icons/sidebar/IconDashboard.vue";
import {
  ROUTE_INDIVIDUAL_PROFILE,
  ROUTE_INDIVIDUAL_PROFILE_PERSONAL_INFO,
  ROUTE_INDIVIDUAL_PROFILE_DEALS,
  ROUTE_INDIVIDUAL_PROFILE_TRACKING,
  ROUTE_INDIVIDUAL_RELATIONS,
  ROUTE_BUSINESSES,
  ROUTE_BUSINESS_ENTITY_PROFILE,
  ROUTE_BUSINESS_PROFILE_DEALS,
  ROUTE_BUSINESS_PROFILE_INFO,
  ROUTE_BUSINESS_TRACKING,
  ROUTE_BUSINESS_RELATIONS
} from "@/router/routes";

const props = defineProps({
  testMode: {
    type: Boolean,
    default: false
  }
});

const route = useRoute();
const { t } = useI18n();
const { getters, commit } = useStore();
const { canPerformActionReactive } = usePermissions();
//TODO: remove once a more permanent solution is in place
const auth = useAuth();

const [canViewIndividualEntityProfiles, canViewBusinessEntityProfiles] = [
  PermissionSubject.individualProfile,
  PermissionSubject.businessProfile
].map((permissionSubject) => {
  return canPerformActionReactive(permissionSubject, Ability.viewAny, {});
});

const settings = computed(() => getters["options/settings"]);
const user = computed<IUser>(() => getters["auth/user"]);

const sidebarExpanded = ref<boolean>(
  settings.value.dashboardMenuExpanded ?? true
);

const dealsHistory = computed(() =>
  (getters["dealsHistoryStack"] as IDealHistoryRecord[]).map((deal) => ({
    label: deal.name,
    link: `/deals/${deal.id}/progress`,
    status: deal.status
  }))
);

const fullUserName = computed(() =>
  [user.value?.first_name, user.value?.last_name].filter(Boolean).join(" ")
);

const dealsActiveCondition = computed(() =>
  routeIncludesNames([
    "DealsBaseView",
    "Deals",
    "DealActivity",
    "DealProgress",
    "FullApp",
    "Documents",
    "Tracking",
    "ApplicationLinks"
  ])
);

const businessesActiveCondition = computed(() =>
  routeIncludesNames([
    ROUTE_BUSINESSES,
    ROUTE_BUSINESS_ENTITY_PROFILE,
    ROUTE_BUSINESS_PROFILE_DEALS,
    ROUTE_BUSINESS_PROFILE_INFO,
    ROUTE_BUSINESS_RELATIONS,
    ROUTE_BUSINESS_TRACKING
  ])
);

const individualProfilesActiveCondition = computed(() =>
  routeIncludesNames([
    ROUTE_INDIVIDUAL_PROFILE,
    ROUTE_INDIVIDUAL_PROFILE_PERSONAL_INFO,
    ROUTE_INDIVIDUAL_PROFILE_DEALS,
    ROUTE_INDIVIDUAL_PROFILE_TRACKING,
    ROUTE_INDIVIDUAL_RELATIONS
  ])
);

const fundersActiveCondition = computed(() =>
  routeIncludesNames(["Funders", "FunderInfo"])
);

const recentSubmenuList = computed(() => {
  return {
    name: t("SIDEBAR.RECENT_DEALS"),
    items: dealsHistory.value
  };
});

const isUserClient = computed(() => {
  if (user.value.roles) {
    return user.value.roles.some((role) => CLIENT_ROLE_GROUP.includes(role));
  }
  return false;
});

const isUserLendflowEmployee = computed(() => {
  if (user.value.roles) {
    return user.value.roles.some((role) => LENDFLOW_ROLE_GROUP.includes(role));
  }
  return false;
});

const isNotLendflowOrClientUser = computed(() => {
  return !isUserLendflowEmployee.value && !isUserClient.value;
});

const reportsAccessRoles = computed(() => {
  const workflowsAssigned =
    auth.currentUser?.permissions_preset.assigned_workflow_template_ids || [];
  const roles = [ADMIN, SUPER_ADMIN, CLIENT_ADMIN];
  if (auth.isAccelerateTaxViewer && workflowsAssigned.length) {
    roles.push(USER);
  }
  return roles;
});

const routeIncludesNames = (routeNames: string[]) => {
  return routeNames.includes(route.name?.toString() ?? "");
};

const toggleSidebar = () => {
  sidebarExpanded.value = !sidebarExpanded.value;
  commit("options/setSettings", {
    key: "dashboardMenuExpanded",
    value: sidebarExpanded.value
  });
};
</script>

<style scoped>
.deals__recent-submenu {
  display: none;
}
.deals__menu-btn::after {
  visibility: hidden;
  position: absolute;
  left: 94%;
  content: "";
  width: 15px;
  height: 40px;
}
.deals__menu-btn {
  position: unset;
}
.deals__menu-btn:hover::after {
  visibility: visible;
}
.deals__menu-btn:hover .deals__recent-submenu {
  display: block;
}
:deep() {
  .icon-dashboard .left,
  .icon-deals .left,
  .icon-activity-hub .board,
  .icon-funders .top,
  .icon-business .bottom,
  .icon-persons .left,
  .icon-integrations .left,
  .icon-customize .top,
  .icon-reports .arrow,
  .icon-profile {
    @apply group-hover/sidebar:text-white transition-all duration-500;
  }
}
:deep(.router-link-active) {
  .icon-dashboard .left,
  .icon-deals .left,
  .icon-activity-hub .board,
  .icon-funders .top,
  .icon-business .bottom,
  .icon-persons .left,
  .icon-integrations .left,
  .icon-customize .top,
  .icon-reports .arrow,
  .icon-profile {
    @apply text-white;
  }
}
</style>
